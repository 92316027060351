import { api } from '.';
import { CREATIVE_RESOURCE, CREATIVE_VIDEO_RESOURCE } from '@/services/keys';

export const getResources = creativeId => ({
  [CREATIVE_RESOURCE]: undefined,
  [CREATIVE_VIDEO_RESOURCE]: creativeId,
});

/**
 *
 * @param {Creative} creativeId
 * @returns {Promise.<object>}
 */
export function updateCreative(creative) {
  const partialUrl = api.createUrl(getResources(creative.id));
  return api.create(partialUrl, creative.payload());
}
