import { api } from '.';
import { CREATIVE_RESOURCE, CREATIVE_VIDEO_RESOURCE } from '@/services/keys';

export const getResources = id => ({
  [CREATIVE_RESOURCE]: undefined,
  [CREATIVE_VIDEO_RESOURCE]: id,
});

/**
 *
 * @param {Creative} creative
 * @returns {Promise.<string>}
 */
export async function deleteCreative(creative) {
  const partialUrl = api.createUrl(getResources(creative.id));
  const {
    data: {
      creative: { id },
    },
  } = await api.delete(partialUrl);
  return id;
}
