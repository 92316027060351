import { api } from '.';
import { INSERTION_RESOURCE, CREATIVE_RESOURCE, CREATIVE_VIDEO_RESOURCE } from '@/services/keys';

export const getResources = insertionId => ({
  [INSERTION_RESOURCE]: insertionId,
  [CREATIVE_RESOURCE]: undefined,
  [CREATIVE_VIDEO_RESOURCE]: undefined,
});

/**
 *
 * @param {Creative} creative
 * @returns {Promise.<object>}
 */
export async function createCreative(creative, insertionId) {
  const partialUrl = api.createUrl(getResources(insertionId));
  const {
    data: { id },
  } = await api.create(partialUrl, creative.payload());
  return id;
}
