import { socialAudience } from '../..';
import { getCreatives } from './getCreatives';
import { getCreativeById } from './getCreativeById';
import { createCreative } from './createCreative';
import { updateCreative } from './updateCreative';
import { deleteCreative } from './deleteCreative';

export const api = socialAudience;

export { getCreatives, getCreativeById, createCreative, updateCreative, deleteCreative };
