import { api } from '.';
import { CREATIVE_RESOURCE, CREATIVE_NATIVE_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import socialAudienceEntityParser from '@/entities/socialAudience/SocialAudienceEntityParser';

export const getResources = creativeId => ({
  [CREATIVE_RESOURCE]: undefined,
  [CREATIVE_NATIVE_RESOURCE]: creativeId,
});

/**
 *
 * @param {String} creativeId
 * @returns {Promise.<{data:Creative,meta:EndpointMeta}>}
 */
export async function getCreativeById(creativeId) {
  const partialUrl = api.createUrl(getResources(creativeId));

  const { data } = await api.get(partialUrl);
  return new ResponseBuilder(socialAudienceEntityParser.parse(data), data.meta).build();
}
