import { api } from '.';
import { INSERTION_RESOURCE, CREATIVE_RESOURCE, CREATIVE_VIDEO_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import socialAudienceEntityParser from '@/entities/socialAudience/SocialAudienceEntityParser';

export const getResources = insertionId => ({
  [INSERTION_RESOURCE]: insertionId,
  [CREATIVE_RESOURCE]: undefined,
  [CREATIVE_VIDEO_RESOURCE]: undefined,
});

/**
 *
 * @param {String} insertionId
 * @returns {Promise.<{data:Creative[],meta:EndpointMeta}>}
 */
export async function getCreatives(insertionId) {
  const partialUrl = api.createUrl(getResources(insertionId));
  const { data } = await api.get(partialUrl);
  return new ResponseBuilder(socialAudienceEntityParser.parse(data), data.meta).build();
}
